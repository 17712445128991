<template>
    <sui-table-row @mousedown="selectRow" :selected="contact.selected">
        <sui-table-cell>
            {{contact.location}}
        </sui-table-cell>
        <sui-table-cell>
            {{contact.contactType}}
        </sui-table-cell>
        <sui-table-cell>
            {{contact.title}}
        </sui-table-cell>
        <sui-table-cell>
            {{contact.contactNumber}}
        </sui-table-cell>
    </sui-table-row>
</template>

<script>
    export default {
        name: "ContactRow",
        props: {
            contact: {
                location: String,
                contactType: String,
                title: String,
                contactNumber: String,

                selected: Boolean
            }
        },
        methods: {
            selectRow: function () {
                this.contact.selected = !this.contact.selected
                if (this.contact.selected) {
                    this.$emit("update:selectRow", this.contact);
                }else{
                    this.$emit("update:unselectRow", this.contact);
                }
            }
        }
    }
</script>

<style scoped>

</style>